import React from 'react'
import Footer from '../footer/footer'
import DefaultLayout from './defaultLayout'
import { Row } from 'react-flexbox-grid'
import Container from '../utils/container';
import Hero from '../hero/hero'
import ImageGallery from '../gallery/imageGallery'
import Img from 'gatsby-image'
import {Link} from 'gatsby'
import {Markdown} from 'react-showdown'
import styled from 'styled-components'
import {
    GalleryWrapper, 
    PrevArrow,
    NextArrow,
    NodeSelector
} from '../gallery/galleryWrapper'



const GalleryLayout = (node) => {
    const {current, prev, next, title, contenu, navigateUrl, bgColor} = node;
    
    const singleNode = (current.slug === next.slug);
    
    return(
        <DefaultLayout>      
        <GalleryWrapper bgColor={bgColor}>
            <Hero title={title} color={bgColor} />
            <Container isFluidMediumDown>
            {!singleNode ?
               <Row between={"xs"} middle={"xs"}>
                <NodeSelector navigateUrl={navigateUrl} node={prev} prev />
                <NodeSelector navigateUrl={navigateUrl} node={current} />
                <NodeSelector navigateUrl={navigateUrl} node={next} next />
               </Row>
               :
               <Row between={"xs"} middle={"xs"}>
                <NodeSelector navigateUrl={navigateUrl} node={current} />
               </Row>
            }
            <div className="creation-info">
                <Markdown markup={current.contenu} />
            </div>
            </Container>
            <ImageGallery images={current.images} />           
        </GalleryWrapper>
        <Footer />
        </DefaultLayout>       
    )
    
    
}

GalleryLayout.defaultProps = {
    subtitle: 'Découvrez nos galeries photos'
}


export default GalleryLayout