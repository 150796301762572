import React from 'react';
import SEO from '../components/utils/SEO';
import GalleryLayout from '../components/layouts/galleryLayout';


const Creation = ({pageContext}) => {
    
    const {current, next, prev} = pageContext;

    return(
        <>
        <SEO title="Nos créations - Tribal Cabane" description="Nos créations, sculptures, cabanes, décorations, bois flotté. Venez découvrir nos différentes oeuvres. Nous cultivons et souhaitons partager une créativité intuitive aliée à la nature à l'aide de moyens d'expressions primitifs et simples à mettre en oeuvre."></SEO> 

        <GalleryLayout 
            current={current}
            next={next}
            prev={prev}
            navigateUrl="cabanes"
            bgColor="#362921"
        />
        </>
    )
}



export default Creation;